import './App.css';
import {MainTR} from './Components/TR/Page'
// import {MainEn} from './Components/EN/Page'
function App() {
  
  return (
    <div className="App">
        <div className="Main">
          <MainTR/>
        </div>
        
      </div>
  );
}

export default App;
