


export const tableItems=[
   
        {Piece:4 ,Pus:  32 ,Fine:28,Year:2021,Hive:"",BrandN:"Honji",Kind:"Süprem"},
        {Piece:5 ,Pus:  34 ,Fine:10,Year:2008,Hive:"28/14",BrandN:"Pailung",Kind:"Süprem"},
        {Piece:4 ,Pus:  34 ,Fine:10,Year:2014,Hive:"28/14",BrandN:"Kervan",Kind:"Süprem"},
        {Piece:1 ,Pus:  32 ,Fine:12,Year:2002,Hive:"22",BrandN:"Jin General",Kind:"Süprem"},
        {Piece:3 ,Pus:  32 ,Fine:22,Year:2000,Hive:"28",BrandN:"Orizo",Kind:"Süprem"},
        {Piece:2 ,Pus:  32 ,Fine:22,Year:2005,Hive:"",BrandN:"Pailung",Kind:"Süprem"},
        {Piece:2 ,Pus:  30 ,Fine:12,Year:2018,Hive:"",BrandN:"Runshan",Kind:"Ribana, Kaşkorse"},
        {Piece:3 ,Pus:  30 ,Fine:12,Year:2002,Hive:"18",BrandN:"Mayer",Kind:"Ribana, Kaşkorse"},
        {Piece:4 ,Pus:  30 ,Fine:12,Year:2000,Hive:"",BrandN:"Mayer",Kind:"Ribana, Kaşkorse"},
        {Piece:2 ,Pus:  36 ,Fine:17,Year:2021,Hive:"",BrandN:"Honji",Kind:"Ribana, Kaşkorse"},
        {Piece:3 ,Pus:  32 ,Fine:20,Year:2021,Hive:"",BrandN:"Honji",Kind:"3 İplik"},
        {Piece:3 ,Pus:  32 ,Fine:20,Year:2021,Hive:"",BrandN:"Morton",Kind:"3 İplik"},
]